import React from 'react';
import styles from './BrainModal.module.scss';
import BrainImage from '../../../../assets/images/HomePage/InformationModal/BrainModal/brainImg.png';
import HeaderIcon from '../../../../assets/images/HomePage/InformationModal/BrainModal/icon.png';
import Image1 from '../../../../assets/images/HomePage/InformationModal/BrainModal/image1.png';
import Image2 from '../../../../assets/images/HomePage/InformationModal/BrainModal/image2.png';
import { TrademarkCircleOutlined } from '@ant-design/icons';

const BrainModal = () => {
  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.headerContent}>
          <div className={styles.brainImage}>
            <img src={BrainImage} alt={BrainImage} />
          </div>
          <div className={styles.headerText}>The Neuroplasticity of the Brain</div>
        </div>
        <div className={styles.headerIcon}>
          <img src={HeaderIcon} alt={HeaderIcon} />
        </div>
      </div>

      <div className={styles.mainContainer}>
        <div className={styles.containerWrapper1}>
          <div className={styles.containerText}>
            <div className={styles.textWrapper1}>
              <p>
                The amazing scientific research regarding the brain and it's capacity to grow and change has transformed
                not only the medical industry but the education industry too!
              </p>
            </div>
            <div className={styles.textWrapper1}>
              NO LONGER are children with learning disabilities deemed to have problems that can't change or be a
              stopping force in their success at school or later on in their future endeavours.
            </div>
          </div>

          <div className={styles.imageContainer}>
            <img src={Image1} alt={Image1} />
          </div>
        </div>
        <div className={styles.containerWrapper2}>
          <div className={styles.containerText}>
            <div className={styles.textWrapper1}>
              <p>
                One of my favourite authors on the topic is Norman Doidge,M.D. who easily explains in common language
                the science behind the brain and it's ability to heal and tranform in his book,
                <span style={{ fontStyle: 'Italic' }}>The Brain That Changes itself</span>.He quotes the claims of the
                neuroplastic,Merzenich, "brain exercises may be as useful as drugs to treat diseases as severe as
                schizophrenia; plasticity exists from the cradle to the grave;and that radical improvements in cognitive
                functioning- how we learn, think perceive, and remember are possible even in the elderly. Merzenich
                argues that practicing a new skill, under the right conditions, can change hundreds of millions and
                possible billions of the connections between the nerve cells in our brain maps."
              </p>
            </div>
            <div className={styles.textWrapper1}>
              I created this program with it's specific activities ranging from using quizes, flashcards, focus
              activities,and full body movement, enhancing the child's brain capacity to learn their basic numeracy,
              spelling and reading comprehension
            </div>
          </div>

          <div className={styles.imageContainer}>
            <img src={Image2} alt={Image2} />
          </div>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <div className={styles.footerText}>Full Body Full Brain Fun</div>
        <div className={styles.copyRight}>
          <TrademarkCircleOutlined
            style={{
              fontSize: '14px',
            }}
          />
          Cogplay
        </div>
      </div>
    </div>
  );
};

export default BrainModal;
