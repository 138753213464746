import createTypes from 'redux-create-action-types';

export default createTypes(
  'ATTEMPT_GET_QUIZ',
  'SUCCESS_GET_QUIZ',
  'FAILURE_GET_QUIZ',
  'ATTEMPT_SET_QUIZ',
  'SUCCESS_SET_QUIZ',
  'FAILURE_SET_QUIZ',
  'SUCCESS_SET_TAB',
  'SUCCESS_SET_RESUME_QUIZ',
  'SUCCESS_SET_TAB_PATH',
  'SUCCESS_SET_ALIEN_MOVE',
  'SET_MODEL_DETAIL',
  'CLEAR_SUCCESS',
  'SUCCESS_AUDIO_RECORDING',
  'GET_ALL_ACTIVITIES_LIST',
  'GET_ALL_ACTIVITIES_LIST_SUCCESS',
  'GET_ALL_ACTIVITIES_LIST_FAILURE',
);
