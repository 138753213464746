import React from 'react';
import styles from './QuizHeader.module.scss';
import CogPlayIcon from '../../../../assets/images/COGPLAYLOGO.svg';
import { ReactComponent as CloseButton } from '../../../../assets/Font/closeButton.svg';
import { useNavigate } from 'react-router-dom';
import ResultModal from '../../ResultModal/ResultModal';
import { useReducerData } from '../../../../store/hooks';
import CustomButton from '../../../../components/Button';
import { stopQuestionTime } from '../../../../helpers/QuestionTimer';

const QuizHeader = ({
  isDisplayNext,
  title,
  handleClose,
  isActivity,
  resultModalVisible,
  setResultModalVisible,
  questionRef,
  isDone,
  isDoneFinish,
  workingDone,
}) => {
  const navigate = useNavigate();
  const tabPath = useReducerData('quiz', 'tabPath');

  const handleCloseClick = () => {
    if (!isDisplayNext) {
      if (!isActivity) {
        // navigate(`/activities`);
        navigate('/');
      } else {
        navigate(tabPath);
      }
    } else {
      if (handleClose) {
        stopQuestionTime();
        handleClose();
        return;
      }
    }
  };
  const handleDoneClick = () => {
    stopQuestionTime();
    if (workingDone) {
      workingDone();
    } else {
      setResultModalVisible(true);
    }
  };
  return (
    <>
      <div className={styles.modalHeader}>
        <div className={styles.cogPlayIconQuiz}>
          <img src={CogPlayIcon} width="88px" alt="somethings went wrong" />
          Cognitive Playground
        </div>
        <div className={styles.headerText}>
          <span>{title}</span>
        </div>

        <div className={styles.closeButton} onClick={isDone ? handleDoneClick : handleCloseClick}>
          {isDone ||
          (handleClose &&
            questionRef?.current !== '05:00' &&
            questionRef?.current !== '5:00' &&
            questionRef?.current !== null) ? (
            <CustomButton
              type="primary"
              loading={isDoneFinish ? isDoneFinish : false}
              disabled={isDoneFinish ? isDoneFinish : false}
            >
              Done
            </CustomButton>
          ) : (
            <div className={styles.closeIcon}>
              <CloseButton />
            </div>
          )}
        </div>
      </div>
      {/* {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )} */}
    </>
  );
};

export default QuizHeader;
