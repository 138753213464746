import React, { useState } from 'react';
import CustomButton from '../../../../components/Button';
import styles from '../Checkout.module.scss';

import modalImage from '../../../../assets/images/modalHeaderImage.png';
import { ReactComponent as CloseIcon } from '../../../../assets/images/closeIconModal.svg';
import CogPlayIcon from '../../../../components/CogPlayIcon/CogPlayIcon';
import { Modal } from 'antd';

const ConfirmModal = (props) => {
  const { hideModal, confirmChange, open } = props;

  const renderContent = () => (
    <div className={styles.mainContainer}>
      <div className={styles.paymentText}>
        <div className={styles.headingText}>
          <span>Change Subscription Confirmation</span>
        </div>
        <div className={styles.subHeadingConfirmText}>
          <span>
            Change subscription will cause cancel your current subscription and your added Child or students are deleted
            <br />
            <br />
          </span>
          <span className={styles.subConfirmText}>
            <b>Are you sure you want to cancel your current subscription?.</b>
          </span>
        </div>
      </div>
      <div className={styles.paymentFooter}>
        <CustomButton type="primary" onClick={confirmChange}>
          Confirm
        </CustomButton>
      </div>

      <CustomButton type="link" onClick={hideModal}>
        Cancel
      </CustomButton>
    </div>
  );

  return (
    <>
      <Modal
        title={<img src={modalImage} alt={modalImage} className={styles.imgHeader} />}
        centered
        open={open}
        onCancel={hideModal}
        className={styles.checkoutModalStyle}
        closeIcon={<CloseIcon height="20px" />}
        footer={null}
      >
        <div className={styles.cogplayIcon}>
          <CogPlayIcon />
        </div>
        {renderContent()}
      </Modal>
    </>
  );
};

export default ConfirmModal;
