import React, { useEffect, useRef, useState } from 'react';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import styles from './SpeedCounting.module.scss';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import { ReactComponent as AudioIcon } from '../../../assets/images/HomePage/Programs/SpeedCounting/audioIcon.svg';
import CustomButton from '../../../components/Button';
import { setDuration, updateQuizPayload, getCurrentRoute } from '../../../helpers/utility';
import { attemptSetQuiz, addAudioRecordFile, getActivitiesListAction } from '../../../store/actions/quizActions';
import { useReducerData } from '../../../store/hooks';
import ResultModal from '../ResultModal/ResultModal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toBuffer from 'audiobuffer-to-wav';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import Lottie from 'lottie-react';
import AudioWavs from '../../../helpers/audioAnimation.json';
import { stopQuestionTime } from '../../../helpers/QuestionTimer';

const SpeedCounting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const questionRef = useRef(null);
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const user = useReducerData('auth', 'user');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const recordingData = useReducerData('quiz', 'recording');

  const { key, subKey } = currentQuizKey[+tabKey];
  const countByNumbers = +tabKey === 2 || +tabKey === 3 ? key * 12 : +tabKey === 0 ? 30 : 30;
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;
  const getUnitName = quizData?.data?.[+tabKey].unitName;
  const [isDoneFinish, setIsDoneFinish] = useState(false);
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [recording, setRecording] = useState(false);
  const [recordingList, setRecordingList] = useState([]);
  const [timeRecord, setTimeRecord] = useState('');

  let totalTime = 5;
  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

  const renderQuiz = () => {
    return (
      <div className={styles.quizContent}>
        {Array(countByNumbers)
          .fill(1)
          .map((value, index, array) => {
            return (
              <div
                className={styles.numberContent}
                style={{
                  color:
                    +tabKey === 2 || +tabKey === 3
                      ? key === 1 || (index + 1) % key === (+tabKey === 3 ? 1 : 0)
                        ? '#FF1616'
                        : '#055CED'
                      : '#ff1616',
                }}
              >
                {+tabKey !== 1 && +tabKey !== 3 ? index + 1 : array.length - 1 - index + 1}
              </div>
            );
          })}
      </div>
    );
  };

  const numberData = () => {
    const newArray = [];

    for (let index = 0; index < countByNumbers; index++) {
      if (+tabKey === 2 || +tabKey === 3) {
        if ((index + 1) % key === (+tabKey === 3 ? (key === 1 ? 0 : 1) : 0)) {
          newArray.push(+tabKey === 3 ? countByNumbers - index : index + 1);
        }
      } else {
        newArray.push(+tabKey === 1 ? countByNumbers - index : index + 1);
      }
    }
    return newArray;
  };

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
        setRecording(true);
      } catch (err) {}
    } else {
    }
  };
  const mimeType = 'audio/wave';
  const startRecording = async () => {
    SpeechRecognition.startListening();
    const media = new MediaRecorder(stream, { type: mimeType });
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const convertBlobToWav = async (audioBlob) => {
    // Convert the audio blob to an ArrayBuffer
    const arrayBuffer = await audioBlob.arrayBuffer();

    // Create an AudioBuffer from the ArrayBuffer
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    // Convert the AudioBuffer to WAV
    const wavBuffer = toBuffer(audioBuffer);

    // Create a WAV blob
    const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });

    return wavBlob;
  };

  const turnOffMicrophone = () => {
    if (stream && stream.getTracks) {
      stream.getTracks().forEach((track) => {
        track.stop();
        track.enabled = false;
      });
    }
  };

  const stopRecording = async (audioData) => {
    setRecording(false);
    SpeechRecognition.stopListening();
    mediaRecorder.current.requestData();
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      turnOffMicrophone();
      const transcriptData =
        transcript &&
        transcript
          ?.split(' ')
          ?.filter((value) => !isNaN(value) && Number(value) !== 0)
          .map(Number);

      convertBlobToWav(audioBlob).then(async (wavBlob) => {
        const formData = new FormData();
        formData.append('recording_file', wavBlob);
        formData.append('recording_list', recordingList?.length > 0 ? recordingList : transcriptData);
        formData.append('category', audioData?.category);
        formData.append('sub_category', audioData?.sub_category);
        formData.append('title', 'Speed Number Counting');
        formData.append('sample_list', numberData());
        formData.append('duration', setDuration(questionRef.current, totalTime));
        formData.append('is_parent', user?.user_type === 'Children' || user?.user_type === 'Student' ? false : true);

        await dispatch(addAudioRecordFile(formData));
        setRecordingList([]);
        setIsDoneFinish(false);
        setResultModalVisible(true);
        setAudioChunks([]);
      });
    };
  };

  const timeOut = async () => {
    if (questionRef.current !== '5:00') {
      setIsDoneFinish(true);
      if (!isDoneFinish) {
        const tempQuizData = updateQuizPayload(
          quizData.data,
          +tabKey,
          key,
          getCurrentRoute(url, currentData),
          setDuration(questionRef.current, totalTime),
        );
        const audioData = {
          category: tempQuizData?.activity?.category,
          sub_category: tempQuizData?.activity?.sub_category,
        };

        stopRecording(audioData);
        await dispatch(
          attemptSetQuiz({
            data: tempQuizData.tempQuizData,
            activity: user?.user_type === 'Children' || user?.user_type === 'Student' ? tempQuizData?.activity : false,
          }),
        );
        dispatch(getActivitiesListAction(getUnitName));
        // stopRecording(audioData);
      }
    }
  };

  const onTimeOut = async () => {
    setIsDoneFinish(true);
    const tempQuizData = updateQuizPayload(
      quizData.data,
      +tabKey,
      key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current, totalTime),
    );

    const audioData = {
      category: tempQuizData?.activity?.category,
      sub_category: tempQuizData?.activity?.sub_category,
    };

    stopRecording(audioData);

    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: user?.user_type === 'Children' || user?.user_type === 'Student' ? tempQuizData?.activity : false,
      }),
    );
    dispatch(getActivitiesListAction(getUnitName));
    // setResultModalVisible(true);
  };

  useEffect(() => {
    if (recording && permission) {
      startRecording();
    }
    if (!browserSupportsSpeechRecognition) {
      // Render some fallback content
    }
  }, [recording]);

  useEffect(() => {
    if (recording && permission) {
      if (!listening) {
        SpeechRecognition.startListening();
        setRecordingList((previous) => {
          const newState = previous.concat(
            transcript
              .split(' ')
              .filter((value) => !isNaN(value) && Number(value) !== 0)
              .map(Number),
          );
          return newState;
        });
      }
    }
  }, [recording, listening]);

  const handleClose = timeRecord !== '5:00' && timeOut;
  const handleCloseClick = () => {
    if (!isDisplayNext) {
      navigate(`/activities`);
    } else {
      if (handleClose) {
        stopQuestionTime();
        handleClose();
        return;
      }
    }
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader
            isDisplayNext={isDisplayNext}
            title={'Speed Number Counting'}
            // handleClose={timeRecord !== '5:00' && timeOut}
            // questionRef={questionRef}
            // resultModalVisible={resultModalVisible}
            // setResultModalVisible={setResultModalVisible}
            // isDoneFinish={isDoneFinish}
            // recordingVoice={stopRecording}
          />
          <div className={styles.instruction}>
            Click on start, say the numbers{+tabKey === 2 || +tabKey === 3 ? ' in red' : ''} out loud as fast as you can
            3 times, click on finish and see your time!
          </div>
          {+tabKey === 2 || +tabKey === 3 ? (
            <div className={styles.multiplyQuizWrapper}>{renderQuiz()}</div>
          ) : (
            <>
              <div className={styles.quizWrapper}>{renderQuiz()}</div>
            </>
          )}

          <div className={styles.audioWrapper}>
            <span className={recording ? styles.recordingSpan : ''}>Say</span>
            {recording ? (
              <Lottie animationData={AudioWavs} loop={true} style={{ height: 130, marginLeft: 10 }} />
            ) : (
              <CustomButton>
                <AudioIcon />
              </CustomButton>
            )}
          </div>
          <QuizFooterHandleTimer
            isDisplayNext={isDisplayNext}
            setIsDisplayNext={setIsDisplayNext}
            isDisabled={true}
            questionRef={questionRef}
            recordingVoice={getMicrophonePermission}
            onTimeOut={onTimeOut}
            totalTime={totalTime}
            setTimeRecord={setTimeRecord}
          />
          <div className={styles.closeButton} onClick={handleCloseClick}>
            {handleClose &&
            questionRef?.current !== '05:00' &&
            questionRef?.current !== '5:00' &&
            questionRef?.current !== null ? (
              <CustomButton
                type="primary"
                loading={isDoneFinish ? isDoneFinish : false}
                disabled={isDoneFinish ? isDoneFinish : false}
              >
                Done
              </CustomButton>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal
          resultModalVisible={resultModalVisible}
          recordingData={recordingData?.percentage}
          isRecodring={true}
          setResultModalVisible={setResultModalVisible}
        />
      )}
    </>
  );
};

export default SpeedCounting;
