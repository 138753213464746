import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import CustomButton from '../../components/Button';
import { InformData } from './InformData';
import InformModal from './Modals/InformModal';
import { Tabs } from 'antd';
import TabInform from '../Quiz/Program_Menu/Tab_Inform';
import { tabs } from '../quizData/tabs';
import { useDispatch, useSelector } from 'react-redux';
import { useReducerData } from '../../store/hooks';

//Actions
import { attemptSetTab, getActivitiesListAction } from '../../store/actions/quizActions';
import { checkSubscriptionApi } from '../../store/actions/subscriptionActions';

import { isLoggedIn } from '../../helpers/utility';
import cs from 'classnames';

const { TabPane } = Tabs;

const programPriority = {
  'Figure 8 activity': 1,
  'Direction Charts': 1,
  'Speed Number Counting': 2,
  'Number Quiz': 2,
  'Colour coded memory cards': 2,
  'Final Quiz': 2,
  'Reward Chart': 2,
  'Number Families': 2,
  'Finger Skip Counting': 2,
  'Brain Warrior Workout': 3,
};
const priorityTitles = [
  'Number Quiz',
  'Speed Number Counting',
  'Final Quiz',
  'Number Families',
  'Colour coded memory cards',
  'Reward Chart',
];

const Home = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [programName, setProgramName] = useState('');
  const dispatch = useDispatch();
  const tabKey = useReducerData('quiz', 'tabKey' || '0');
  const modelTitle = useReducerData('quiz', 'modelTitle');
  const [getIsUnitName, setIsunitName] = useState('Addition Unit');

  const onChange = async (key) => {
    const findUnitType = tabs.find((val) => val.tab.key == key);
    setIsunitName(findUnitType.tab.type);
    await dispatch(attemptSetTab(key));
  };
  const modelHandle = () => {
    if (priorityTitles.includes(modelTitle)) {
      return 2;
    }

    if (programName) {
      scrollToCard();
      return programPriority[programName];
    }

    if (modelTitle === 'Figure 8 activity' || modelTitle === 'Direction Charts') {
      return 1;
    }
    if (modelTitle === 'Brain Warrior Workout') {
      return 3;
    }
  };

  const scrollToCard = (index) => {
    const serviceToScroll = document.getElementById(`info_card_${index}`);
    serviceToScroll?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (isLoggedIn() && user?.user_type !== 'Admin') {
      dispatch(checkSubscriptionApi());
    }
  }, [user]);

  useEffect(() => {
    dispatch(getActivitiesListAction(getIsUnitName));
  }, [getIsUnitName]);

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.infoContainer}>
          {InformData.map((element, index) => {
            return (
              <div
                className={cs(styles.detailContainer, {
                  [styles.modelHighlight]: modelHandle() === index,
                })}
                key={index}
                id={`info_card_${index}`}
                onClick={scrollToCard(index)}
              >
                <div className={styles.imageContainer}>
                  <img src={element.image} width="100%" alt={element.image} />
                </div>
                <div className={styles.detailMainContainer}>
                  <div className={styles.headingContainer}>
                    <span>{element.text}</span>
                  </div>
                  <div className={styles.subTextContainer}>
                    <span>{element.subText}</span>
                  </div>
                </div>

                <div className={styles.buttonContainer}>
                  <CustomButton
                    type="primary"
                    onClick={() => {
                      setIsModalVisible(true);
                      setModalIndex(index);
                    }}
                  >
                    Read more
                  </CustomButton>
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.ProgramsMainContainer}>
          <div className={styles.programContainer}>
            <div className={styles.heading}>
              <span>Math program</span>
            </div>
            <div className={styles.tabContainer}>
              <Tabs defaultActiveKey="0" activeKey={tabKey} onChange={onChange} centered>
                {tabs.map((tabData, i) => (
                  <TabPane tab={tabData?.tab?.type} key={i}>
                    <TabInform tabKey={tabKey} setProgramName={setProgramName} tabDataType={tabData.tab} />
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </div>
          <div className={styles.informationContainer}>
            All young learners will be capable of acquiring their basic numeracy skills in a multi-sensory, fun,
            activity based Math program, specifically designed for youthful, active students including students with
            Language Based Learning Disabilities, particularly ADD/ADHD and Spectrum learners.
          </div>
        </div>
      </div>

      <InformModal isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} modalIndex={modalIndex} />
    </>
  );
};

export default Home;
