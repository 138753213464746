import Types from '../types/quizTypes';
import Cookies from 'js-cookie';
import { addAudioFIle, getQuiz, setQuiz, addVideoFIle, getActivitiesList } from '../../api/quizApi';
import { notification } from 'antd';
import { newTabs } from '../../pages/quizData/tabs';
import { doLogout } from './authActions';

export const attemptGetQuiz = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.ATTEMPT_GET_QUIZ,
    });

    const resp = await getQuiz();
    if (resp.status === 1) {
      if (!resp?.[0]) {
        dispatch(
          attemptSetQuiz({
            data: newTabs,
            activity: false,
          }),
        );
      } else {
        dispatch({
          type: Types.SUCCESS_GET_QUIZ,
          payload: resp[0] || { data: {} },
        });
      }
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      const tokenQuiz =
        user?.user_type === 'Student' || user?.user_type === 'Children'
          ? sessionStorage.getItem('token')
          : Cookies.get('token');
      if (user && tokenQuiz && resp?.message?.detail === 'Invalid token.') {
        dispatch(doLogout());
      } else {
        notification['error']({
          message: 'Something went wrong, please try again later',
        });
      }
      dispatch({
        type: Types.FAILURE_GET_QUIZ,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp.status;
  };
};

export const attemptSetQuiz = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.ATTEMPT_SET_QUIZ,
    });

    const resp = await setQuiz(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.SUCCESS_SET_QUIZ,
        payload: resp || { data: {} },
      });
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      const tokenQuiz =
        user?.user_type === 'Student' || user?.user_type === 'Children'
          ? sessionStorage.getItem('token')
          : Cookies.get('token');
      if (user && tokenQuiz && resp?.message?.detail === 'Invalid token.') {
        dispatch(doLogout());
        // notification['error']({
        //   message: 'Your subscription has been cancelled',
        // });
      } else {
        notification['error']({
          message: 'Something went wrong, please try again later',
        });
      }
      dispatch({
        type: Types.FAILURE_SET_QUIZ,
        status: resp.status,
        message: resp.message,
      });
    }
    return resp.status;
  };
};

export const addAudioRecordFile = (payload) => {
  return async (dispatch) => {
    const resp = await addAudioFIle(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.SUCCESS_AUDIO_RECORDING,
        payload: resp?.data || { data: {} },
      });
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
    }
    return resp.status;
  };
};

export const addVideoRecordFile = (payload) => {
  return async () => {
    const resp = await addVideoFIle(payload);
    if (resp.status === 1) {
    } else {
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
    }
    return resp.status;
  };
};

export const attemptSetTab = (payload) => {
  return (dispatch) => {
    dispatch({
      type: Types.SUCCESS_SET_TAB,
      payload,
    });
  };
};

export const attemptSetResumeQuiz = (payload) => {
  return (dispatch) => {
    dispatch({
      type: Types.SUCCESS_SET_RESUME_QUIZ,
      payload,
    });
  };
};

export const attemptSetTabPath = (payload) => {
  return (dispatch) => {
    dispatch({
      type: Types.SUCCESS_SET_TAB_PATH,
      payload,
    });
  };
};

export const attemptSetAlienMovePoint = (payload) => {
  return (dispatch) => {
    dispatch({
      type: Types.SUCCESS_SET_ALIEN_MOVE,
      payload,
    });
  };
};

export const attemptClearData = () => {
  return async (dispatch) => {
    dispatch({
      type: Types.CLEAR_SUCCESS,
    });
  };
};

export const attemptSetModelDetail = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: Types.SET_MODEL_DETAIL,
      payload,
    });
  };
};

export const getActivitiesListAction = (payload) => {  
  return async (dispatch) => {
    dispatch({
      type: Types.GET_ALL_ACTIVITIES_LIST,
    });

    const resp = await getActivitiesList(payload);
    if (resp.status === 1) {
      dispatch({
        type: Types.GET_ALL_ACTIVITIES_LIST_SUCCESS,
        payload: resp,
      });
    } else {
      dispatch({
        type: Types.GET_ALL_ACTIVITIES_LIST_FAILURE,
      });
      notification['error']({
        message: 'Something went wrong, please try again later',
      });
    }
    console.log('resp getActivitiesList==>', resp);

    return resp.status;
  };
};
