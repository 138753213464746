import { Form } from 'antd';
import React from 'react';
import CustomButton from '../../../components/Button/Button';
import CustomInput from '../../../components/Input';
import styles from './forgotPassword.module.scss';

const SendEmail = ({ handleSend, loading, resetPasswordhandleChange, forgotPasswordData, form, emailSent }) => {
  return (
    <div className={styles.formInput}>
      <Form colon={false} onFinish={handleSend} autoComplete="off" form={form}>
        <CustomInput
          label={
            <div>
              Email
              <div style={{ marginBottom: '30px' }}>
                <span className={styles.spanMessage}>You will get an email with recovery link</span>
              </div>
            </div>
          }
          inputType="email"
          name="email"
          value={forgotPasswordData.email}
          onChange={resetPasswordhandleChange}
          placeholder="Enter your email"
          rules={[
            {
              required: true,
              message: 'Please enter your email !',
            },
            {
              type: 'email',
              message: 'Please enter valid E-mail !',
            },
          ]}
          allowClear
        />
        {emailSent ? (
          <div onClick={handleSend} className={styles.linkClass}>
            Didn’t get the email? Send again
          </div>
        ) : (
          <CustomButton type="primary" loading={loading} htmlType="submit">
            Send
          </CustomButton>
        )}
      </Form>
    </div>
  );
};

export default SendEmail;
