import React, { useRef, useState, useEffect } from 'react';
import styles from './NumberFamilies.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import { Form } from 'antd';
import { NumberFamiliesSubTractionData, NumberFamiliesDivisionData } from './NumberFamiliesData';
import CartoonImage from '../../../assets/images/HomePage/Programs/NumberFamilies/cartoon.png';
import { setDuration, updateQuizPayload, getCurrentRoute } from '../../../helpers/utility';
import { attemptSetQuiz, getActivitiesListAction } from '../../../store/actions/quizActions';
import { useDispatch } from 'react-redux';
import { useReducerData } from '../../../store/hooks';
import Question from './Question';
import ResultModal from '../ResultModal/ResultModal';
import { notification } from 'antd';

const NumberFamilies = () => {
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const { key, subKey } = currentQuizKey[+tabKey];
  const questionRef = useRef(null);
  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [quizQuestionKey, setQuizQuestionKey] = useState(0);
  const [checkTimeOut, setCheckTimeOut] = useState(false);
  const [answerData, setAnswerData] = useState([]);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const [isDoneFinish, setIsDoneFinish] = useState(false);
  const totalTime = 10;
  const [result, setResult] = useState({
    correctAnswer: 0,
    wrongAnswer: 0,
  });
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;
  const getUnitName = quizData?.data?.[+tabKey].unitName

  const [isCorrectData, setIsCorrectData] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });
  const isFalse = isCorrectData['0'] && isCorrectData['1'] && isCorrectData['2'] && isCorrectData['3'];
  const handleChange = ({ target: { name, value } }) => {
    setAnswerData((prev) => {
      const newAnswer = [...prev];
      newAnswer[name] = value;
      return newAnswer;
    });
    setIsCorrectData({
      ...isCorrectData,
      [name]:
        +tabKey !== 3
          ? key + NumberFamiliesSubTractionData[quizQuestionKey]?.num1 === +value
          : key * NumberFamiliesDivisionData[quizQuestionKey]?.num1 === +value,
    });
  };
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      if (
        answerData.length === 4 &&
        isFalse &&
        answerData.reduce((prev, curr) => {
          if (prev) {
            if (!curr) {
              prev = false;
            }
          }
          return prev;
        }, true)
      ) {
        let givenCorrectAnswer = 0;
        const correctAnswer =
          +tabKey !== 3
            ? key + NumberFamiliesSubTractionData[quizQuestionKey]?.num1
            : key * NumberFamiliesDivisionData[quizQuestionKey]?.num1;
        answerData.forEach((value) => {
          if (correctAnswer === +value) {
            givenCorrectAnswer++;
          }
        });
        const selectedQuestionKey =
          +tabKey === 1 ? NumberFamiliesSubTractionData.length - 1 : NumberFamiliesDivisionData.length - 1;
        if (quizQuestionKey !== selectedQuestionKey)
          setResult((prev) => ({
            correctAnswer: prev.correctAnswer + givenCorrectAnswer,
            wrongAnswer: prev.wrongAnswer + 4 - givenCorrectAnswer,
          }));
        form.resetFields();
        setAnswerData([]);
        if (quizQuestionKey === selectedQuestionKey) {
          setIsDoneFinish(true);
          if (!isDoneFinish) {
            setCheckTimeOut(false);
            const correctAnswer = result.correctAnswer + givenCorrectAnswer;
            const wrongAnswer = result.wrongAnswer + 4 - givenCorrectAnswer;
            const tempQuizData = updateQuizPayload(
              quizData.data,
              +tabKey,
              key,
              getCurrentRoute(url, currentData),
              setDuration(questionRef.current, totalTime),
              wrongAnswer,
              correctAnswer,
            );

            await dispatch(
              attemptSetQuiz({
                data: tempQuizData?.tempQuizData,
                activity: tempQuizData?.activity,
              }),
            );
            dispatch(getActivitiesListAction(getUnitName));
            setResultModalVisible(true);
            setIsDoneFinish(false);
          }
        } else {
          setQuizQuestionKey(quizQuestionKey + 1);
        }
      }
      if (e?.target?.value) {
        const nextInputRef = document.getElementById(`question-${parseInt(e?.target?.name) + 1}`);
        if (nextInputRef) {
          nextInputRef.focus();
        }
        if (answerData?.length === 4) {
          !isFalse &&
            notification['error']({
              message: 'Please ensure all fields are filled correctly before submitting.',
            });
        }
      }
    }
  };

  useEffect(() => {
    if (checkTimeOut) {
      afterCheckTimeOut();
    }
  }, [checkTimeOut]);

  const afterCheckTimeOut = async () => {
    // let givenCorrectAnswer = 4;
    form.resetFields();
    setAnswerData([]);
    const correctAnswer = result.correctAnswer;
    const wrongAnswer = result.wrongAnswer;

    const tempQuizData = updateQuizPayload(
      quizData.data,
      +tabKey,
      key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current, totalTime),
      wrongAnswer,
      correctAnswer,
    );

    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );
    dispatch(getActivitiesListAction(getUnitName));
    setResultModalVisible(true);
  };

  const questionProps = { handleChange, handleKeyDown, isDisplayNext, form };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.modalContainer}>
        <QuizHeader isDisplayNext={false} title={'Number Family Memory Cards'} />
        <div className={styles.instruction}>Fill all 4 blanks with the missing family member, then press enter</div>
        <div className={styles.quizWrapper}>
          <div className={styles.quizImageBody}>
            <div className={styles.triangle}>
              <div className={styles.subtriangle}>
                <div className={styles.numberUpper}>
                  {+tabKey === 1
                    ? NumberFamiliesSubTractionData[quizQuestionKey]?.num1
                    : NumberFamiliesDivisionData[quizQuestionKey]?.num1}
                </div>
                <div className={styles.imageWrapper}>
                  <img src={CartoonImage} alt={CartoonImage} height="500px" />
                </div>
                <div className={styles.numberWrapper}>
                  <div className={styles.numberLeft}>{key}</div>
                  <div className={styles.numberRight}>
                    {+tabKey !== 3
                      ? key + NumberFamiliesSubTractionData[quizQuestionKey]?.num1
                      : key * NumberFamiliesDivisionData[quizQuestionKey]?.num1}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.questionOperations}>
            <div className={styles.questionBody}>
              <div className={styles.questionRow}>
                <Question
                  variableA={key}
                  variableB={
                    +tabKey === 1
                      ? NumberFamiliesSubTractionData[quizQuestionKey]?.num1
                      : NumberFamiliesDivisionData[quizQuestionKey]?.num1
                  }
                  sign={+tabKey !== 3 ? '+' : 'x'}
                  name={0}
                  inputRef={inputRef}
                  {...questionProps}
                />
                <Question
                  variableA={
                    +tabKey === 1
                      ? NumberFamiliesSubTractionData[quizQuestionKey]?.num1
                      : NumberFamiliesDivisionData[quizQuestionKey]?.num1
                  }
                  variableB={key}
                  sign={+tabKey !== 3 ? '+' : 'x'}
                  name={1}
                  inputRef={inputRef}
                  {...questionProps}
                />
              </div>
              <div className={styles.questionRow}>
                <Question
                  variableA={
                    +tabKey === 3 && key === 0
                      ? key === 0
                        ? '∞'
                        : key
                      : +tabKey === 1
                      ? NumberFamiliesSubTractionData[quizQuestionKey]?.num1
                      : NumberFamiliesDivisionData[quizQuestionKey]?.num1
                  }
                  variableB={key}
                  sign={+tabKey !== 3 ? '-' : '÷'}
                  name={2}
                  inputRef={inputRef}
                  numberKey={
                    +tabKey === 1
                      ? NumberFamiliesSubTractionData[quizQuestionKey]?.num1
                      : NumberFamiliesDivisionData[quizQuestionKey]?.num1
                  }
                  keyValue={key}
                  {...questionProps}
                />
                <Question
                  variableA={result?.correctAnswer === 0 && +tabKey === 3 ? '∞' : key}
                  variableB={
                    +tabKey === 1
                      ? NumberFamiliesSubTractionData[quizQuestionKey]?.num1
                      : NumberFamiliesDivisionData[quizQuestionKey]?.num1
                  }
                  sign={+tabKey !== 3 ? '-' : '÷'}
                  name={3}
                  inputRef={inputRef}
                  keyValue={key}
                  {...questionProps}
                />
              </div>
            </div>
          </div>
        </div>
        <QuizFooterHandleTimer
          isDisplayNext={isDisplayNext}
          setIsDisplayNext={setIsDisplayNext}
          isDisabled={true}
          questionRef={questionRef}
          totalTime={10}
          isContiune={true}
          setCheckTimeOut={setCheckTimeOut}
        />
      </div>
      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}
    </div>
  );
};

export default NumberFamilies;
