import React, { useState, useRef, useEffect } from 'react';
import styles from './CountBySeven.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import QuizFooterHandleTimer from '../QuizLayout/QuizFooter/QuizFooterHandleTimer';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useDispatch } from 'react-redux';
import { attemptSetQuiz, getActivitiesListAction } from '../../../store/actions/quizActions';
import { setDuration, updateQuizPayload, getCurrentRoute } from '../../../helpers/utility';
import { useReducerData } from '../../../store/hooks';
import ResultModal from '../ResultModal/ResultModal';

const CountBySeven = () => {
  const dispatch = useDispatch();
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const { key, subKey } = currentQuizKey[+tabKey];
  const questionRef = useRef(null);
  const countBySevenNumbers = 144;
  const [isDisplayNext, setIsDisplayNext] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);

  const { browserSupportsSpeechRecognition } = useSpeechRecognition();
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;
  const getUnitName = quizData?.data?.[+tabKey].unitName

  useEffect(() => {
    if (isDisplayNext) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [isDisplayNext]);

  const showUnSupported = () => {
    if (!browserSupportsSpeechRecognition && isDisplayNext) {
      return <span>Browser doesn't support speech recognition.</span>;
    } else return <></>;
  };

  const renderQuiz = () => {
    return (
      <div className={styles.quizContent}>
        {Array(countBySevenNumbers)
          .fill(1)
          .map((value, index, array) => {
            return (
              <div
                className={styles.numberContent}
                style={{
                  color: (index + 1) % 7 === 0 ? '#FF1616' : '#055CED',
                }}
              >
                {index + 1}
              </div>
            );
          })}
      </div>
    );
  };

  const timeOut = async () => {
    const tempQuizData = updateQuizPayload(
      quizData.data,
      +tabKey,
      key,
      getCurrentRoute(url, currentData),
      setDuration(questionRef.current),
    );
    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );
    dispatch(getActivitiesListAction(getUnitName));
    setResultModalVisible(true);
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader
            isDisplayNext={isDisplayNext}
            title={'Count by Sevens'}
            handleClose={timeOut}
            tabKey={tabKey}
            questionRef={questionRef}
            resultModalVisible={resultModalVisible}
            setResultModalVisible={setResultModalVisible}
          />
          <div className={styles.instruction}>Say the numbers in red in order as fast as you can! 3 times!</div>
          <div className={styles.quizWrapper}>{renderQuiz()}</div>
          <QuizFooterHandleTimer
            isDisplayNext={isDisplayNext}
            setIsDisplayNext={setIsDisplayNext}
            isDisabled={true}
            questionRef={questionRef}
          />
          {showUnSupported()}
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}
    </>
  );
};

export default CountBySeven;
