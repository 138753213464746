import React, { Component } from 'react';
import { useLocation, Route } from 'react-router-dom';
import '../../assets/css/animate.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import AdminNavbar from '../../components/Navbars/AdminNavbar';
import Sidebar from '../../components/Sidebar';
import { useDispatch, useSelector } from 'react-redux';

//actions
import { doLogout } from '../../store/actions/authActions';

import routes from '../../routes.js';

import sidebarImage from '../../assets/img/sidebar-3.jpg';

function Admin({ children }) {
  const [hasImage, setHasImage] = React.useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const user = useSelector((state) => state.auth.user);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
      var element = document.getElementById('bodyClick');
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const adminRoutes = routes.filter((item) => {
    if (item.layout === 'admin' && user?.user_type === 'Admin') {
      return item.isAdmin;
    } else {
      if (user?.user_type !== 'Admin' && item?.isTeacherAndParent) {
        return item?.isTeacherAndParent;
      }
    }
  });

  const handleLogout = () => {
    dispatch(doLogout());
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar
          color={'black'}
          image={hasImage ? sidebarImage : ''}
          routes={adminRoutes}
          handleLogout={handleLogout}
        />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar user={user} />
          <div className="content">{children}</div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}

export default Admin;
