import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes } from 'react-router';
import { AuthLayout, PublicLayout, AdminLayout } from './layout';
import RoutesList from './routes';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { isLoggedIn } from './helpers/utility';
import { useDispatch, useSelector } from 'react-redux';
import { loginUserData } from './store/hooks';
import { notification } from 'antd';
import { useIdleTimer } from 'react-idle-timer';
//actions
import { doLogout } from './store/actions/authActions';
import CustomModal from './components/CustomModal';

const App = () => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(isLoggedIn());
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const userData = useSelector((state) => state.auth);
  const { user_subscription, user, isSubscriptionCancel } = userData;
  const navigate = useNavigate();

  const location = useLocation();

  const { pathname, hash } = location;
  const parts = pathname.split('/');
  const screenName = parts[parts.length - 1];
  const checkScreen = screenName + hash;

  const handleOnIdle = (event) => {
    if (isLogin) {
      dispatch(doLogout());
      navigate('/');
      pause();
      notification['info']({
        message: 'Session has been expired',
      });
    }
  };

  const { pause, reset } = useIdleTimer({
    timeout: 1000 * 60 * ((user?.user_type === 'Student' || user?.user_type === 'Children') ? 5 : 1440),
    onIdle: isLogin ? handleOnIdle : () => null,
    debounce: 500,
  });

  useEffect(() => {
    setIsLogin(isLoggedIn());
    if (isLogin && checkScreen === 'brain-warrior-workout#true') {
      pause();
    } else {
      isLogin && reset();
    }
    if (isSubscriptionCancel) {
      setFeedBackModal(true);
    }
  }, [user, checkScreen, isSubscriptionCancel]);


  useEffect(() => {
    window.addEventListener('online', () => setIsOffline(false));
    window.addEventListener('offline', () => setIsOffline(true));
  }, []);

  const renderRoutes = () => {
    const renderRoute = (Component, layout, isPrivate, isSubcribe, isAdmin, isTeacherAndParent) => {
      if (Component) {
        switch (layout) {
          case 'admin':
            return isLogin &&
              isPrivate &&
              user?.user_type === 'Admin' &&
              user?.user_type !== 'Children' &&
              user?.user_type !== 'Student' ? (
              <AdminLayout>
                <Component />
              </AdminLayout>
            ) : (
              <Navigate to="/login" />
            );
          case 'layout':
            return isLogin &&
              loginUserData() &&
              isPrivate &&
              (user_subscription?.length ||
                user?.user_type === 'Children' ||
                user?.user_type === 'Student' ||
                !isAdmin) ? (
              isSubcribe ? (
                isTeacherAndParent && user?.user_type !== 'Admin' ? (
                  <AdminLayout>
                    <Component />
                  </AdminLayout>
                ) : (
                  <PublicLayout>
                    <Component />
                  </PublicLayout>
                )
              ) : user_subscription && user_subscription?.length ? (
                isTeacherAndParent && user?.user_type !== 'Admin' ? (
                  <AdminLayout>
                    <Component />
                  </AdminLayout>
                ) : (
                  <PublicLayout>
                    <Component />
                  </PublicLayout>
                )
              ) : (
                <Navigate to="/" />
              )
            ) : (
              <Navigate to="/" />
            );
          case 'isLogin':
            return isLogin && loginUserData() && isPrivate ? (
              <PublicLayout>
                <Component />
              </PublicLayout>
            ) : (
              <Navigate to="/" />
            );
          case 'auth':
            return isLogin && loginUserData() ? (
              <Navigate to="/" />
            ) : (
              <AuthLayout>
                <Component />
              </AuthLayout>
            );
          case 'isChild':
            return isLogin && loginUserData() && (user?.user_type === 'Children' || user?.user_type === 'Student') ? (
              <Navigate to="/" />
            ) : (
              <PublicLayout>
                <Component />
              </PublicLayout>
            );

          case 'public':
          default:
            return (
              <PublicLayout>
                <Component />
              </PublicLayout>
            );
        }
      }
      return null;
    };

    return RoutesList.map((route) => (
      <Route
        key={route.name}
        path={route.path}
        element={renderRoute(
          route.component,
          route.layout,
          route.isPrivate,
          route.isSubcribe,
          route.isAdmin,
          route?.isTeacherAndParent,
        )}
      />
    ));
  };

  return (
    <div className="App">
      {/* <Elements stripe={stripePromise}> */}
      {/* </Elements> */}
      {isOffline ? (
        <div>
          <h2>No Internet Connection</h2>
          <h3>Please check your internet connection and try again.</h3>
        </div>
      )
        : (
          <><Routes>{renderRoutes()}</Routes>
            <CustomModal feedBackModal={feedBackModal} setFeedBackModal={setFeedBackModal} /></>
        )
      }
    </div>
  );
};

export default App;
