import Request from './request';

export const getQuiz = async () => {
  return Request.call({
    url: 'api/v1/quiz/',
    method: 'GET',
  });
};

export const setQuiz = async (payload) => {
  return Request.call({
    url: 'api/v1/quiz/',
    method: 'POST',
    data: payload,
  });
};

export const addAudioFIle = async (payload) => {
  return Request.call({
    url: 'api/v1/speed-count/',
    method: 'POST',
    data: payload,
  });
};

export const addVideoFIle = async (payload) => {
  return Request.call({
    url: 'api/v1/figure_eight/',
    method: 'POST',
    data: payload,
  });
};

export const getActivitiesList = async (data) => {
  return Request.call({
    url: `api/v1/user-activities/?unit_name=${data}`,
    method: 'GET',
  });
};
