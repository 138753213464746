import React from 'react';
import styles from './FullBodyModal.module.scss';
import VideoImage from '../../../../assets/images/HomePage/InformationModal/FullBodyModal/brainVideo.png';

const FullBodyModal = () => {
  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.textWrapper}>
          <p>
            "Therapeutically, cross crawl refers to any intentional cross-lateral activity in which you cross the
            mid-line of the body, such as touching opposite hand and knee or foot. Performing this movement builds the
            bridge between the right and left hemispheres of the brain,allowing for electrical impulses and information
            to pass freely between the two, which is essential for physical coordination as well as cerebral activites,such
            as learning language,reading,and hand-to-eye coordination."
          </p>
          <div className={styles.linkWrapper}>
            <a href="https://www.yourtherapysource.com/blog1/2019/06/16/cross-crawl/" target="blank">
              https://www.yourtherapysource.com/blog1/2019/06/16/cross-crawl/
            </a>
          </div>
        </div>
        <div className={styles.headingContainer}>Cross Body Movement with Hemi-Sync Music</div>
        <div className={styles.videoWrapper}>
          <img src={VideoImage} alt={VideoImage} />
        </div>
        <div className={styles.textWrapper}>
          <p>
            "This acoustic alchemy put into practice has yielded significant results in the exploration of expanded
            consciousness states,creative capability development, learning processes acceleration,learning disabilities,
            focus and concentration, wellness,pain relief,anxiety and depression amelioration,as well as in improved
            sleep,hypnosis induction,meditation and relaxation."
          </p>
          <div className={styles.linkWrapper}>
            <a href="http://www.wseas.us/e-library/conferences/2010/lasi/AMTA/AMTA-18.pdf" target="blank">
              http://www.wseas.us/e-library/conferences/2010/lasi/AMTA/AMTA-18.pdf
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullBodyModal;
