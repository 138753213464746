import React, { useEffect } from 'react';
import modalImage from '../../../assets/images/modalHeaderImage.png';
import { ReactComponent as CloseIcon } from '../../../assets/images/closeIconModal.svg';
import { Modal } from 'antd';
import styles from './Checkout.module.scss';
import CogPlayIcon from '../../../components/CogPlayIcon/CogPlayIcon';
import PaymentModal from './PaymentsModal/PaymentModal';
import PaymentConfirmModal from './PaymentConfirmModal/PaymentConfirmModal';
import PaymentSuccessModal from './PaymentSuccess/PaymentSuccessModal';
import { useLocation } from 'react-router';

const CheckoutModal = ({
  modalVisible,
  setModalVisible,
  heading,
  price,
  isConfirmModal,
  setIsConfirmModal,
  loading,
  setLoading,
  isPaymentSuccess,
  setIsPaymentSuccess,
  stages,
  setStages,
  setActiveButton1,
  planId,
  selectPlan,
  setSelectPlan,
}) => {
  const location = useLocation();
  const renderContent = (activeModal) => {
    const modalProps = { setIsConfirmModal, setStages };
    switch (activeModal) {
      case 1:
        return (
          <PaymentModal 
            setModalVisible={setModalVisible}
            heading={heading}
            price={price}
            paymentPlan={selectPlan?.item?.nickname === 'Parents' ? 'Parent with 1 child' : 'Teacher with 10 students'}
            loading={loading}
            setLoading={setLoading}
            planId={planId}
            {...modalProps}
          />
        );
      case 2:
        return (
          <PaymentConfirmModal
            setIsPaymentSuccess={setIsPaymentSuccess}
            {...modalProps}
            selectPlan={selectPlan}
            setSelectPlan={setSelectPlan}
          />
        );
      case 3:
        return <PaymentSuccessModal />;
      default:
        return <PaymentModal />;
    }
  };

  const closeModal = ()=>{
      if (stages === 1) {
        setModalVisible(false);
      } else if (stages === 2) {
        setIsConfirmModal(false);
      } else {
        setIsPaymentSuccess(false);
      }
      setActiveButton1(false);
      location.state = null
  }
  
  return (
    <>
      <Modal
        title={<img src={modalImage} alt={modalImage} className={styles.imgHeader} />}
        open={stages === 1 ? modalVisible : stages === 2 ? isConfirmModal : isPaymentSuccess}
        centered
        onCancel={closeModal}
        className={styles.checkoutModalStyle}
        closeIcon={<CloseIcon height="20px" />}
        footer={null}
      >
        <>
          <div className={styles.cogplayIcon}>
            <CogPlayIcon />
          </div>
          {renderContent(stages)}
        </>
      </Modal>
    </>
  );
};

export default CheckoutModal;
