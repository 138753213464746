import React, { useEffect, useState } from 'react';
import styles from './BrainWarriorWorkout.module.scss';
import QuizHeader from '../QuizLayout/QuizHeader/QuizHeader';
import ReactPlayer from 'react-player';
import { ReactComponent as PlayBtn } from '../../../assets/images/playBtn.svg';
import Thumbnail from '../../../assets/images/thumbnail.png';
import { Spin } from 'antd';

import { source, brainWorkout } from '../../User_Profile/User_Quiz_data';
import { attemptSetQuiz, getActivitiesListAction } from '../../../store/actions/quizActions';
import { useDispatch } from 'react-redux';
import { useReducerData } from '../../../store/hooks';
import ResultModal from '../ResultModal/ResultModal';
import { updateQuizPayload, getCurrentRoute } from '../../../helpers/utility';
import { useNavigate } from 'react-router-dom';

const BrainWarriorWorkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quizData = useReducerData('quiz', 'quizData');
  const tabKey = useReducerData('quiz', 'tabKey');
  const currentQuizKey = useReducerData('quiz', 'currentQuizKey');
  const { key, subKey } = currentQuizKey[+tabKey];
  const [playing, setPlaying] = useState(false);
  const [isShow, setIsShow] = useState(true);

  const [ready, setReady] = useState(false);
  const [resultModalVisible, setResultModalVisible] = useState(false);
  const url = window.location.href;
  const currentData = quizData?.data?.[+tabKey]?.quiz[key]?.data;
  const getUnitName = quizData?.data?.[+tabKey].unitName
  useEffect(() => {
    setTimeout(() => {
      setIsShow(false);
    }, 500);
  }, []);

  const videoCompleted = async () => {
    const tempQuizData = updateQuizPayload(quizData.data, +tabKey, key, getCurrentRoute(url, currentData));

    await dispatch(
      attemptSetQuiz({
        data: tempQuizData?.tempQuizData,
        activity: tempQuizData?.activity,
      }),
    );
    dispatch(getActivitiesListAction(getUnitName));
    setResultModalVisible(true);
  };

  const renderQuiz = () => {
    return (
      <ReactPlayer
        url={Number(tabKey) === 0 || Number(tabKey) === 2 ? source : brainWorkout}
        controls={true}
        width="100%"
        height="100%"
        playIcon={<span></span>}
        light={
          <div style={{ height: '100%' }}>
            <img src={Thumbnail} alt="Thumbnail" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            {!isShow && !playing && (
              <PlayBtn
                className={styles.playBtn}
                onClick={() => {
                  handlePlay();
                  setReady(true);
                }}
              />
            )}
          </div>
        }
        onReady={() => setReady(false)}
        playing={resultModalVisible ? !resultModalVisible : playing}
        onPlay={handlePlay}
        onStart={handlePlay}
        onPause={handlePause}
        onEnded={() => videoCompleted()}
        style={{ height: '100%', display: ready ? 'none' : 'flex' }}
      />
    );
  };

  const handlePlay = () => {
    setPlaying(true);
    navigate(`#true`);
  };

  const handlePause = () => {
    setPlaying(false);
    navigate(``);
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.modalContainer}>
          <QuizHeader isDisplayNext={false} title={'Brain Warrior Workout'} />
          <div className={styles.quizWrapper}>
            {ready ? (
              <Spin tip="Loading" size="large">
                <div style={{ height: '100%' }}>
                  <img src={Thumbnail} alt="Thumbnail" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
              </Spin>
            ) : null}
            {renderQuiz()}
          </div>
        </div>
      </div>
      {resultModalVisible && (
        <ResultModal resultModalVisible={resultModalVisible} setResultModalVisible={setResultModalVisible} />
      )}
    </>
  );
};

export default BrainWarriorWorkout;
